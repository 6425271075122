/* eslint-disable max-classes-per-file */
import {
  AiFiFlavor,
  LoopFlavor,
  ZabkaFlavor,
  StreetFlavor,
  MorrisonsFlavor,
  RioFlavor,
  Flavor,
  HebFlavor,
  AldiFlavor,
  VerizonFlavor,
  VanillaFlavor,
  ChengduFlavor,
  CoconutFlavor,
  ShanghaiFlavor,
  TRCAFlavor,
  BoxLLFlavor,
  MiamiFlavor,
  MSFTFlavor,
  DGFlavor,
  VerizonUltraFlavor,
  VerizonUltraUKFlavor,
  CainzFlavor,
  REWEFlavor,
  AVFCFlavor,
  LCFCFlavor,
  ClippersFlavor,
  ChoiceFlavor,
  DACIFlavor,
  MercedesBenzFlavor,
  LevyFlavor,
  GoldenStateWarriorsFlavor,
  PWCFlavor,
  KansasCityChiefs,
  PhoenixSuns,
  PhiladelphiaEagles,
  NewJerseyDevils,
  FloridaPanthers,
  BrightonFlavor,
  MSFTSingaporeFlavor,
  FCCopenhagen,
} from ".";

export enum AppFlavor {
  "CAINZ",
  "VERIZONULTRAUK",
  "VERIZONULTRA",
  "DG",
  "MSFT",
  "MIAMI",
  "LOOP",
  "ZABKA",
  "RIO",
  "STREET",
  "MORRISONS",
  "HEB",
  "ALDI",
  "VERIZON",
  "MASTER",
  "VANILLA",
  "CHENGDU",
  "COCONUT",
  "SHANGHAI",
  "TRCA",
  "BoxLL",
  "REWE",
  "LCFC",
  "AVFC",
  "CLIPPERS",
  "CHOICE",
  "DACI",
  "MERCEDESBENZ",
  "LEVY",
  "GOLDENSTATEWARRIORS",
  "PWC",
  "KANSASCITYCHIEFS",
  "PHOENIXSUNS",
  "PHILADELPHIAEAGLES",
  "NEWJERSEYDEVILS",
  "FLORIDAPANTHERS",
  "BRIGHTON",
  "MSFTSINGAPORE",
  "FCCOPENHAGEN",
}

const flavorNameToObject: { [key: string]: Flavor } = {
  Cainz: CainzFlavor,
  VerizonUltraUK: VerizonUltraUKFlavor,
  VerizonUltra: VerizonUltraFlavor,
  DG: DGFlavor,
  MSFT: MSFTFlavor,
  Miami: MiamiFlavor,
  Loop: LoopFlavor,
  Zabka: ZabkaFlavor,
  Rio: RioFlavor,
  Street: StreetFlavor,
  Morrisons: MorrisonsFlavor,
  HEB: HebFlavor,
  Aldi: AldiFlavor,
  Verizon: VerizonFlavor,
  Master: AiFiFlavor,
  Vanilla: VanillaFlavor,
  Coconut: CoconutFlavor,
  Shanghai: ShanghaiFlavor,
  TRCA: TRCAFlavor,
  BoxLL: BoxLLFlavor,
  Chengdu: ChengduFlavor,
  REWE: REWEFlavor,
  AVFC: AVFCFlavor,
  LCFC: LCFCFlavor,
  CLIPPERS: ClippersFlavor,
  Choice: ChoiceFlavor,
  DACI: DACIFlavor,
  MercedesBenz: MercedesBenzFlavor,
  Levy: LevyFlavor,
  GoldenStateWarriors: GoldenStateWarriorsFlavor,
  PWC: PWCFlavor,
  KANSASCITYCHIEFS: KansasCityChiefs,
  PHOENIXSUNS: PhoenixSuns,
  PHILADELPHIAEAGLES: PhiladelphiaEagles,
  NEWJERSEYDEVILS: NewJerseyDevils,
  FLORIDAPANTHERS: FloridaPanthers,
  BRIGHTON: BrightonFlavor,
  MSFTSINGAPORE: MSFTSingaporeFlavor,
  FCCOPENHAGEN: FCCopenhagen,
};

const appFlavorToFlavorObject: { [key in AppFlavor]: Flavor } = {
  [AppFlavor.CAINZ]: CainzFlavor,
  [AppFlavor.VERIZONULTRAUK]: VerizonUltraUKFlavor,
  [AppFlavor.VERIZONULTRA]: VerizonUltraFlavor,
  [AppFlavor.DG]: DGFlavor,
  [AppFlavor.MSFT]: MSFTFlavor,
  [AppFlavor.MIAMI]: MiamiFlavor,
  [AppFlavor.LOOP]: LoopFlavor,
  [AppFlavor.ZABKA]: ZabkaFlavor,
  [AppFlavor.RIO]: RioFlavor,
  [AppFlavor.STREET]: StreetFlavor,
  [AppFlavor.MORRISONS]: MorrisonsFlavor,
  [AppFlavor.HEB]: HebFlavor,
  [AppFlavor.ALDI]: AldiFlavor,
  [AppFlavor.VERIZON]: VerizonFlavor,
  [AppFlavor.MASTER]: AiFiFlavor,
  [AppFlavor.VANILLA]: VanillaFlavor,
  [AppFlavor.CHENGDU]: ChengduFlavor,
  [AppFlavor.COCONUT]: CoconutFlavor,
  [AppFlavor.SHANGHAI]: ShanghaiFlavor,
  [AppFlavor.TRCA]: TRCAFlavor,
  [AppFlavor.BoxLL]: BoxLLFlavor,
  [AppFlavor.REWE]: REWEFlavor,
  [AppFlavor.AVFC]: AVFCFlavor,
  [AppFlavor.LCFC]: LCFCFlavor,
  [AppFlavor.CLIPPERS]: ClippersFlavor,
  [AppFlavor.CHOICE]: ChoiceFlavor,
  [AppFlavor.DACI]: DACIFlavor,
  [AppFlavor.MERCEDESBENZ]: MercedesBenzFlavor,
  [AppFlavor.LEVY]: LevyFlavor,
  [AppFlavor.GOLDENSTATEWARRIORS]: GoldenStateWarriorsFlavor,
  [AppFlavor.PWC]: PWCFlavor,
  [AppFlavor.KANSASCITYCHIEFS]: KansasCityChiefs,
  [AppFlavor.PHOENIXSUNS]: PhoenixSuns,
  [AppFlavor.PHILADELPHIAEAGLES]: PhiladelphiaEagles,
  [AppFlavor.NEWJERSEYDEVILS]: NewJerseyDevils,
  [AppFlavor.FLORIDAPANTHERS]: FloridaPanthers,
  [AppFlavor.BRIGHTON]: BrightonFlavor,
  [AppFlavor.MSFTSINGAPORE]: MSFTSingaporeFlavor,
  [AppFlavor.FCCOPENHAGEN]: FCCopenhagen,
};

const flavorToName: { [key in AppFlavor]: string } = {
  [AppFlavor.CAINZ]: "Cainz",
  [AppFlavor.VERIZONULTRAUK]: "VerizonUltraUK",
  [AppFlavor.VERIZONULTRA]: "VerizonUltra",
  [AppFlavor.DG]: "DG",
  [AppFlavor.MSFT]: "MSFT",
  [AppFlavor.MIAMI]: "Miami",
  [AppFlavor.LOOP]: "Loop",
  [AppFlavor.ZABKA]: "Zabka",
  [AppFlavor.RIO]: "Rio",
  [AppFlavor.STREET]: "Street",
  [AppFlavor.MORRISONS]: "Morrisons",
  [AppFlavor.HEB]: "HEB",
  [AppFlavor.ALDI]: "Aldi",
  [AppFlavor.VERIZON]: "Verizon",
  [AppFlavor.MASTER]: "Master",
  [AppFlavor.VANILLA]: "Vanilla",
  [AppFlavor.CHENGDU]: "Chengdu",
  [AppFlavor.COCONUT]: "Coconut",
  [AppFlavor.SHANGHAI]: "Shanghai",
  [AppFlavor.TRCA]: "TRCA",
  [AppFlavor.BoxLL]: "BoxLL",
  [AppFlavor.REWE]: "REWE",
  [AppFlavor.LCFC]: "LCFC",
  [AppFlavor.AVFC]: "AVFC",
  [AppFlavor.CLIPPERS]: "Clippers",
  [AppFlavor.CHOICE]: "Choice",
  [AppFlavor.DACI]: "DACI",
  [AppFlavor.MERCEDESBENZ]: "MercedesBenz",
  [AppFlavor.LEVY]: "Levy",
  [AppFlavor.GOLDENSTATEWARRIORS]: "GoldenStateWarriors",
  [AppFlavor.PWC]: "PWC",
  [AppFlavor.KANSASCITYCHIEFS]: "KANSASCITYCHIEFS",
  [AppFlavor.PHOENIXSUNS]: "PHOENIXSUNS",
  [AppFlavor.PHILADELPHIAEAGLES]: "PHILADELPHIAEAGLES",
  [AppFlavor.NEWJERSEYDEVILS]: "NEWJERSEYDEVILS",
  [AppFlavor.FLORIDAPANTHERS]: "FLORIDAPANTHERS",
  [AppFlavor.BRIGHTON]: "BRIGHTON",
  [AppFlavor.MSFTSINGAPORE]: "MSFTSINGAPORE",
  [AppFlavor.FCCOPENHAGEN]: "FCCOPENHAGEN",
};

const getKeyFromMapOrThrow = (key: any, mapping: any) => {
  const returnVal = mapping[key];

  if (!returnVal) {
    throw new KeyNotInMapError(`Incorrect key ${key} for mapping ${mapping}`);
  }

  return returnVal;
};

export const getFlavor: (name: string) => Flavor = (name) =>
  getKeyFromMapOrThrow(name, flavorNameToObject);

export const getClassForFlavor: (flavor: AppFlavor) => Flavor = (flavor) =>
  getKeyFromMapOrThrow(flavor, appFlavorToFlavorObject);

export const getFlavorName: (flavor: AppFlavor) => string = (flavor) =>
  getKeyFromMapOrThrow(flavor, flavorToName);

export class NotImplementedError extends Error {}

class KeyNotInMapError extends Error {}
