
import { StyleSheet } from "react-native";
import { Flavor } from "src/lib/flavors";
import { color2 } from "src/styles";
import { hexToRgba } from "../../utils";

export const styles = (flavor: Flavor) =>
  StyleSheet.create({
    wrapper: {
      flexDirection: "row",
      alignItems: "center",
    },
    tagWrapper: {
      padding: 6,
      margin: 5,
      backgroundColor: hexToRgba(flavor.getMainColor(), 0.2),
      borderRadius: 4,
      overflow: "hidden",
      alignSelf: "flex-start",
      justifyContent: "center",
    },
    title: {
      fontSize: 10,
      fontWeight: "bold",
      color: color2.BLUE_AIFI,
    },
    img: {
      marginRight: 3,
    },
  });
