import { LogoFCCopenhagen } from "src/components/LogoFCCopenhagen";
import { color2 } from "../../styles";
import { Flavor } from "./flavor";
import { IFeatureFlags } from "../../../../shared/interface";

class FCCopenhagenFlavor extends Flavor {
  getName() {
    return "FC Copenhagen";
  }

  getFullName() {
    return "FC Copenhagen";
  }

  getMainColor() {
    return color2.FCCOPENHAGEN;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.FCCOPENHAGEN];
  }

  getDashLineColor() {
    return color2.FCCOPENHAGEN;
  }

  getAPIEndpoint() {
    return "";
  }

  getCircleLogo() {
    return <LogoFCCopenhagen />;
  }

  getFeatureFlags(): IFeatureFlags {
    return {
      ...super.getFeatureFlags(),
      contestingEnabled: false,
    };
  }

  getStripePublicKey() {
    return "";
  }
}

const Singleton = new FCCopenhagenFlavor();
export default Singleton;
