import { LogoBrighton } from "src/components/LogoBrighton";
import { color2 } from "../../styles";
import { Flavor } from "./flavor";

class BrightonFlavor extends Flavor {
  getName() {
    return "Brighton Albion";
  }

  getFullName() {
    return "Brighton Albion";
  }

  getMainColor() {
    return color2.BRIGHTON;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.BRIGHTON];
  }

  getDashLineColor() {
    return color2.BRIGHTON;
  }

  getAPIEndpoint() {
    return "";
  }

  getCircleLogo() {
    return <LogoBrighton />;
  }

  getStripePublicKey() {
    return "";
  }
}

const Singleton = new BrightonFlavor();
export default Singleton;
