import { LogoMSFT } from "src/components/LogoMSFTSingapore";
import { color2 } from "../../styles";
import { Flavor } from "./flavor";

class MSFTSingaporeFlavor extends Flavor {
  getName() {
    return "MSFT";
  }

  getFullName() {
    return "MSFT";
  }

  getMainColor() {
    return color2.MSFT_MAIN;
  }

  getLinearGradientBackground() {
    return [color2.WHITE, color2.MSFT_MAIN];
  }

  getDashLineColor() {
    return color2.MSFT_MAIN;
  }

  getAPIEndpoint() {
    return "";
  }

  getCircleLogo() {
    return <LogoMSFT />;
  }

  getStripePublicKey() {
    return "";
  }
}

const Singleton = new MSFTSingaporeFlavor();
export default Singleton;
